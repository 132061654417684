.featuredCleaningCategoriesCard
{
    display: flex;
    padding: 10px;
    border-radius: 10px;
    gap: 20px;
    align-items: center;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
}

.featuredCleaningCategoriesCard:hover
{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.featuredCleaningCategoriesImg
{
    height: 100px;
    width: 200px;
}

.featuredCleaningCategoriesImg img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.featuredCleaningCategoriesDetails
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.featuredCleaningCategoriesDetails .cleaningTitle
{
    font-size: 16px;
    font-weight: 800;
    color: var(--theme-color1);
}

.featuredCleaningCategoriesCard:hover .cleaningTitle
{
    color: var(--theme-color);
}

.featuredCleaningCategoriesDetails .cleaningDesc
{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2; 
    -webkit-box-orient: vertical;
    font-size: 13px;
    color: var(--lighter-color);
}