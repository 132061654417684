.booking
{
    position: relative;
}

.bookingContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 40px;
    flex-direction: column;
}

.bookingContainer .err
{
    font-size: 14px;
    font-weight: 600;
    color: var(--theme-color);
    margin-bottom: 10px;
}

.bookingCardContainer
{
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.bookingCardContainerHead
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bookingCardContainerHead .title
{
    font-size: 18px;
    font-weight: 900;
    color: var(--lighter-color);
}

.bookingCardContainerHead .close
{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: var(--theme-color3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bookingCardContainerHead .close i
{
    font-size: 20px;
}

.bookingCardContainerHead .close:hover i
{
    color: var(--theme-color2);
}

.bookingCardContainerBody
{
    display: flex;
    gap: 40px;
}

.bookingCalendar
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bookingCalendar label,
.bookingCardDetailsItem .title
{
    font-size: 17px;
    font-weight: 700;
    color: var(--black-color);
}

.bookingDetails
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bookingCardDetailsItem
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bookingLocation 
{
    display: flex;
    align-items: center;
    gap: 20px;
}

.bookingLocationItem
{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    font-weight: 600;
    color: var(--lighter-color);
}

.bookingLocationItem span.active
{
    color: var(--black-color);
}

.bookingLocationItem input,
.bookingCleaningTypesItem input
{
    height: 20px;
    width: 20px;
    cursor: pointer;
    accent-color: var(--theme-color);
    outline: none;
    box-shadow: none;
    border: 2px solid var(--theme-color);
}

.bookingCardDetailsItem .locationInput,
.bookingCardDetailsItem textarea
{
    padding: 8px;
    border-radius: 3px;
    background-color: var(--theme-color3);
    border: none;
    font-weight: 17px;
    font-weight: 700;
    color: var(--black-color1);
}

.bookingCardDetailsItem .locationInput:focus,
.bookingCardDetailsItem textarea:focus
{
    border: none;
    outline: none;
}

.bookingCleaningTypes
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bookingCleaningTypesItem
{
    display: flex;
    align-items: center;
    gap: 20px;
}

.bookingCleaningTypesItem .type
{
    font-size: 16px;
    font-weight: 700;
    color: var(--lighter-color);
}

.bookingCardDetailsItem.btn
{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.bookingCardDetailsItem.btn .btnSubmit
{
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 700;
    background-color: var(--theme-color);
    color: var(--white-color);
    cursor: pointer;
}