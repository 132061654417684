.singleServiceListing
{
    display: flex;
    justify-content: center;
}

.singleServiceListingContainer
{
    padding-top: 50px;
    padding-bottom: 40px;
    width: 100%;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.singleServiceListingBody
{
    display: flex;
    gap: 50px;
}

.singleServiceListingImage .logo
{
    height: 250px;
    width: 250px;
    border-radius: 10px;
    border: 1px solid var(--lighter-color);
}

.singleServiceListingImage .logo img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.singleServiceListingDetails
{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.singleServiceListingHeader
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.singleServiceListingHeaderButtons
{
    display: flex;
    align-items: center;
    gap: 20px;
}

.singleServiceListingHeaderButtons span
{
    padding: 8px 15px;
    border-radius: 5px;
    border: 1px solid var(--theme-color);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.singleServiceListingHeaderButtons span.bookFav
{
    color: var(--theme-color1);
    border-color: var(--theme-color1);
    padding: 4px 8px;
}

.singleServiceListingHeaderButtons span.bookFav i
{
    font-size: 28px;
}

.singleServiceListingHeaderButtons span.bookFav:hover,
.singleServiceListingHeaderButtons span.bookFav.active
{
    background-color: var(--theme-color1);
    color: var(--white-color);
}

.singleServiceListingHeaderButtons span.bookService
{
    background-color: var(--theme-color);
    color: var(--white-color);
}

.singleServiceListingHeaderButtons span.bookService:hover
{

    background-color: var(--white-color);
    color: var(--theme-color);
}

.location
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.location i
{
    font-size: 20px;
    color: var(--theme-color1);
}

.location .locationTitle
{
    font-size: 14px;
    font-weight: 300;
    color: var(--theme-color);
    text-transform: capitalize;
}

.singleServiceListingHeader .title
{
    font-size: 20px;
    font-weight: 700;
    color: var(--black-color);
}

.singleServiceListingDetails .desc p
{
    font-size: 14px;
    font-weight: 200;
    color: var(--lighter-color);
}

.serviceContactDetails
{
    display: flex;
    gap: 40px;
}

.serviceItems,
.serviceItemsDetails
{
    display: flex;
    gap: 5px;
    flex-direction: column;
    font-size: 14px;
    color: var(--lighter-color);
}

.serviceItemsDetails .loc
{
    text-transform: capitalize;
}

.serviceContactIcon
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.serviceContactIcon i
{
    font-size: 22px;
}

.serviceContactIcon span
{
    font-weight: 900;
}

.serviceTypes
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.serviceTypes .title
{
    font-size: 15px;
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 10px;
}

.serviceTypeItem
{
    display: flex;
    align-items: center;
    gap: 25px;
}

.serviceTypeItem .logo
{
    height: 25px;
    width: 25px;
}

.serviceTypeItem .logo img
{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.serviceTypeItem span
{
    font-size: 16px;
    font-weight: 100;
}

.separator
{
    display: flex;
    align-items: center;
    gap: 15px;
}

.separator .sepH
{
    border: 1px solid var(--theme-color3);
    flex: auto;
}

.singleServiceListingReviews
{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.singleServiceListingReviewsBody .reviews
{
    display: flex;
    flex-direction: column;
    gap: 20px;
} 

.singleServiceListingAddReview
{
    display: flex;
    flex-direction: column;
}

.singleServiceListingAddReview .title
{
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.singleServiceListingAddReview .title:hover
{
    text-decoration: underline;
}

.singleServiceListingAddReview .small
{
    font-size: 12px;
    font-weight: 100;
    color: var(--lighter-color);
}

.reviewForm
{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    align-items: flex-start;
}

.reviewFormItem
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.reviewFormItem .rating i
{
    font-size: 24px;
    color: var(--theme-color1);
}

.reviewFormItem textarea
{
    width: 250px;
    padding: 10px;
}

.reviewFormItem textarea:focus
{
    outline: none;
}

.reviewBtn
{
    border-radius: 5px;
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 4px 10px;
    cursor: pointer;
}