.featuredCleaningCategories
{
    width: 100%;
    display: flex;
    justify-content: center;
}

.featuredCleaningCategoriesContainer
{
    width: 100%;
    padding-bottom: 40px;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.featuredCleaningCategoriesHeader
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featuredCleaningCategoriesHeader .title
{
    font-size: 26px;
    font-weight: 800;
    text-align: center;
}

.featuredCleaningCategoriesHeader .title .s1
{
    color: var(--theme-color);
}

.featuredCleaningCategoriesBody
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
}