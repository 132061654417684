.featuredCityCard
{
    border-radius: 10px;
    padding: 10px;
    background-color: var(--white-color);
    cursor: pointer;
}

.featuredCityCard:hover
{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.featuredCityCardContainer
{
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
}

.featuredCityCardImg
{
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.featuredCityCardImg img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.featuredCityCardImgCover
{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-image: linear-gradient(var(--transparent-color), var(--transparent-color), var(--transparent-color), var(--black-color));
}

.featuredCityCardDetails
{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.featuredCityCardDetails .cityName
{
    background-color: var(--theme-color1);
    border-radius: 25px;
    font-size: 15px;
    font-weight: 600;
    padding: 2px 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.featuredCityCardDetails .cityName i
{
    font-size: 20px;
    color: var(--white-color);
}

.featuredCityCardDetails .count
{
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    color: var(--white-color);
    text-align: center;
    text-transform: capitalize;
}

.featuredCityCard:hover .count
{
    text-decoration: underline;
}