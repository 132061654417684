.header
{
    display: flex;
    justify-content: center;
}

.headerContainer
{
    width: 100%;
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 10px;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    position: relative;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    position: relative;
}

.headerIntro
{
    font-size: 14px;
    font-weight: 500;
    color: var(--lighter-color);
    line-height: 14px;
}

.headerIntro .s1
{
    color: var(--theme-color);
}

.headerIntro .s2
{
    color: var(--theme-color2);
}

.headerTitle
{
    font-size: 48px;
    font-weight: 900;
    letter-spacing: normal;
    line-height: 56px;
}

.headerTitle .s1
{
    color: var(--theme-color);
}

.headerTitle .s2
{
    color: var(--theme-color1);
}
.headerDesc
{
    font-size: 12px;
    font-weight: 400;
    color: var(--lighter-color);
}

.headerSearch
{
    background-color: var(--white-color);
    margin-top: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.headerSearchContainer
{
    display: flex;
    align-items: center;
    gap: 15px;
}

.headerSearchItem
{
    border: 1px solid var(--light-color2);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerSearchItem i
{
    font-size: 24px;
}

.headerSearchItem select,
.headerSearchItem input
{
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: var(--black-color1);
}

.headerSearchItem select:focus,
.headerSearchItem input:focus
{
    outline: none;
}

.headerBtn
{
    padding: 10px 30px;
    background-color: var(--theme-color);
    font-size: 16px;
    font-weight: 700;
    color: var(--white-color);
    cursor: pointer;
}

