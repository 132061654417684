.navbar
{
    width: 100%;
    height: 70px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9;
    position: relative;
}

.navContainer
{
    width: 100%;
    max-width: calc(100vw - 120px);
    color: var(--black-color1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 9;
}

.navContainer .navLink
{
    text-decoration: none;
    color: var(--theme-color);
    letter-spacing: 0.1em;
    font-weight: 900;
}

.navTitle
{
    display: flex;
    align-items: center;
    gap: 40px;
}

.navTitle h1
{
    font-size: 24px;
    font-weight: bolder;
}

.navTitle span
{
    color: var(--theme-color2);
}

.navLinks
{
    display: flex;
    align-items: center;
    gap: 40px;
}

.navLinks a
{
    text-decoration: none;
    color: var(--black-color1);
}

.navLinks span
{
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    text-transform: capitalize;
    border-bottom: 0.15em solid var(--white-color);
}

.navLinks span:hover
{
    color: var(--theme-color);
}

.navLinks span.active
{
    color: var(--theme-color);
    border-color: var(--theme-color);
}

.navbarMenu
{
    padding: 5px 10px;
    border: 1px solid var(--light-color);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
}

.navbarMenu i
{
    font-size: 20px;
}

.navbarMenu:hover
{
    background-color: var(--theme-color);
    color: var(--white-color);
}

.navbarMenuProfile
{
    position: relative;

}

.navbarMenuProfile .title
{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    border: 0.0125em solid var(--light-color);
    border-radius: 5px;
    cursor: pointer;
}

.navbarMenuProfile .menu
{
    width: 100%;
    position: absolute;
    top: 40px;
    background-color: var(--white-color);
    padding: 10px;
    margin: 0;
    list-style: none;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.navbarMenuProfile .menu li
{
    cursor: pointer;
    padding: 5px;
    border-radius: 2px;
}

.navbarMenuProfile .menu li:hover
{
    background-color: var(--theme-color3);
}

.navbarMenuProfile .menu li:last-child
{
    color: var(--theme-color2);
}
