.featuredCleaningOperatorCard
{
    border: 0.0125em solid var(--light-color2);
    border-radius: 10px;
    cursor: pointer;
}

.featuredCleaningOperatorCard:hover
{
    border-color: var(--light-color);
}

.featuredCleaningOperatorCardContainer
{
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.featuredCleaningOperatorLogo
{
    height: 70px;
    width: 70px;
}

.featuredCleaningOperatorLogo img
{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.featuredCleaningOperatorDetails
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.operatorDetailsTitle
{
    font-size: 15px;
    font-weight: 700;
    color: var(--black-color1);
}

.operatorDetailsRating
{
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--theme-color1);
    font-size: 14px;
}

.operatorDetailsRating .reviews
{
    color: var(--lighter-color);
    font-size: 12px;
    font-weight: 100;
    margin-left: 5px;
}