.contact
{
    position: relative;
}

.container 
{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.container .content 
{
    width: 100%;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container .content .left-side 
{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
}

.content .left-side::before 
{
    content: "";
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
}

.content .left-side .details 
{
    margin: 14px;
    text-align: center;
}

.content .left-side .details i 
{
    font-size: 30px;
    color: var(--theme-color);
    margin-bottom: 10px;
}

.content .left-side .details .topic 
{
    font-size: 18px;
    font-weight: 500;
}

.content .left-side .details .text-one,
.content .left-side .details .text-two 
{
    font-size: 14px;
    color: var(--lighter-color);
}

.container .content .right-side 
{
    width: 75%;
    margin-left: 75px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.content .right-side .topic-text 
{
    font-size: 48px;
    font-weight: 900;
    color: var(--theme-color);
}

.right-side .input-box 
{
    height: 55px;
    width: 100%;
    margin: 12px 0;
}

.right-side .input-box input,
.right-side .input-box textarea 
{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: var(--theme-color3);
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
}

.right-side .message-box 
{
    min-height: 110px;
}

.right-side .input-box textarea 
{
    padding-top: 6px;
}

.right-side .button 
{
    display: inline-block;
    margin-top: 12px;
}

.right-side .button input[type="button"] 
{
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: var(--theme-color);
    cursor: pointer;
    transition: all 0.3s ease;
}

.button input[type="button"]:hover 
{
    background: var(--theme-color);
}

@media (max-width: 950px) {
    .container 
    {
        width: 90%;
        padding: 30px 40px 40px 35px;
    }

    .container .content .right-side 
    {
        width: 75%;
        margin-left: 55px;
    }
}
@media (max-width: 820px) 
{
    .container {
        margin: 40px 0;
        height: 100%;
    }

    .container .content 
    {
        flex-direction: column-reverse;
    }

    .container .content .left-side
    {
        width: 100%;
        flex-direction: row;
        margin-top: 40px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .container .content .left-side::before 
    {
        display: none;
    }

    .container .content .right-side 
    {
        width: 100%;
        margin-left: 0;
    }
}