.about
{
    position: relative;
}

.aboutContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 70px;
}

.about-us 
{
    width: 100%;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 90px 0;
    background: #fff;
}

.picContainer
{
    height: 400px;
    width: 400px;
    border-radius: 12px;
    background-color: var(--theme-color3);
}

.pic 
{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.about-usContainer 
{
    width: 1130px;
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
}

.text 
{
    width: 540px;
}

.text h2 
{
    color: var(--theme-color);
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 10px;
}

.text h5 
{
    color: var(--black-color);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

.text h5 span 
{
    color: var(--theme-color1);
}

.text p 
{
    color: var(--lighter-color);
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
}

.data 
{
    margin-top: 30px;
}

.hire 
{
    font-size: 16px;
    background: var(--theme-color);
    color: var(--white-color);
    text-decoration: none;
    border: none;
    padding: 12px 25px;
    border-radius: 6px;
    transition: 0.5s;
}

.hire:hover 
{
    background: var(--theme-color3);
    color: var(--black-color);
}