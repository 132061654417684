.footer
{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--theme-color3);
    border-top: 1px solid var(--light-color2);
}

.footerContainer
{
    width: 100%;
    padding-top: 40px;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footerContainerDetails
{
    display: flex;
    justify-content: space-between;
}

.companyDetails
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footerTitle
{
    display: flex;
    align-items: center;
    gap: 40px;
}

.footerTitle h1
{
    font-size: 24px;
    font-weight: bolder;
}

.footerTitle span
{
    color: var(--theme-color2);
}

.footerDesc p
{
    font-size: 13px;
    color: var(--lighter-color);
}

.socials
{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.socialsTitle
{
    font-size: 17px;
    font-weight: 700;
}

.socialMediaItems
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socialMediaItems span
{
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 1px solid var(--light-color2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialMediaItems span:hover
{
    background-color: var(--theme-color2);
}

.socialMediaItems span i
{
    font-size: 24px;
    color: var(--theme-color5);
}

.socialMediaItems span:hover i
{
    color: var(--white-color);
}

.servicesDetails,
.citiesDetails,
.shortLinks
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.servicesTitle,
.citiesTitle,
.shortLinksTitle
{
    font-size: 17px;
    font-weight: 700;
}

.servicesDetails ul,
.citiesDetails ul,
.shortLinks ul
{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.servicesDetails ul li,
.citiesDetails ul li,
.shortLinks ul li
{
    font-size: 16px;
    color: var(--lighter-color);
    font-weight: 500;
    cursor: pointer;
}

.citiesDetails ul li
{
    display: flex;
    align-items: center;
}

.citiesDetails ul li i
{
    color: var(--black-color);
    margin-right: 5px;
}

.servicesDetails ul li:hover,
.citiesDetails ul li .cityFooter:hover,
.shortLinks ul li:hover
{
    text-decoration: underline;
}

.footer .searchInput
{
    margin-top: 30px;
    display: flex;
    align-items: center;
    border: 1px solid var(--light-color2);
}

.footer .searchInput input
{
    padding: 8px;
    border: none;
}

.footer .searchInput input:focus
{
    outline: none;
}

.footer .searchInput .searchInputBtn
{
    background-color: var(--theme-color);
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    color: var(--white-color);
    cursor: pointer;
}

.footerSeparator
{
    height: 0.01em;
    width: 100%;
    background-color: var(--light-color);
}

.footerDiv
{
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerDivCopyright p
{
    font-size: 14px;
    font-weight: 500;
}

.footerDivItems ul
{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin: 0;
    padding: 0;
    margin-left: 15px;
}

.footerDivItems ul .item-footer
{
    font-size: 14px;
    font-weight: 500;
    color: var(--black-color1);
    cursor: pointer;
}

.footerDivItems ul .item-footer:hover
{
    text-decoration: underline;
}