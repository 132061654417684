.home
{
    position: relative;
}

.homeContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
