.dashboardAccount
{
    display: flex;
    flex-direction: column;
}

.dashboardAccountHeader
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.dashboardAccountHeader h3
{
    font-weight: 900;
    font-size: 18px;
}

.accountDetailsEdit
{
    display: flex;
    justify-content: flex-end;
}

.editBtn
{
    margin-top: 20px;
    padding: 7px 25px;
    border: 1px solid var(--theme-color9);
    color: var(--theme-color9);
    cursor: pointer;
}

.accountDetails
{
    display: flex;
    flex-direction: column;
}

.accountDetailsPhoto
{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.accountDetailsPhoto .photo
{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: relative;
    border: 0.0125em solid var(--lighter-color);
}

.accountDetailsPhoto .photo span
{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    right: 0;
    border: 2px solid var(--light-color);
    border-radius: 50%;
    background-color: var(--lighter-color2);
    cursor: pointer;
}

.accountDetailsPhoto .photo span i
{
    font-size: 18px;
    color: var(--theme-color6);
}

.accountDetailsPhoto .photo span input
{
    display: none;
}

.accountDetailsPhoto .photo img
{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.uploadPhoto
{
    padding: 3px 15px;
    font-size: 14px;
    font-weight: 200;
    color: var(--white-color);
    background-color: var(--theme-color);
    border-radius: 3px;
    cursor: pointer;
}

.accountDetailsInfo
{
    margin-top: 50px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    min-width: 0;
}

.accountDetailsInfoItem
{
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.accountDetailsInfoItem label
{
    font-size: 16px;
    font-weight: 500;
    color: var(--black-color);
}

.accountDetailsInfoItem span
{
    font-size: 14px;
    font-weight: 500;
    color: var(--lighter-color);
    text-transform: capitalize;
}

.no-show
{
    display: none;
}
