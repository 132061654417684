.servicesSearch
{
    display: flex;
    justify-content: center;
}

.servicesSearchContainer
{
    padding-top: 50px;
    padding-bottom: 40px;
    width: 100%;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.servicesSearchHeader
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.servicesSearchHeader .title
{
    font-size: 48px;
    font-weight: 900;
    color: var(--black-color);
    text-align: center;
    line-height: 48px;
    text-transform: capitalize;
}

.servicesSearchHeader .title .s1
{
    color: var(--theme-color1);
}

.servicesSearchHeader .title .s2
{
    color: var(--theme-color);
}

.servicesSearchHeader p
{
    font-size: 14px;
    font-weight: 500;
    color: var(--light-color);
    text-align: center;
}

.servicesSearchBody
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
}

.servicesSearchFilters
{
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.servicesSearchFiltersItem
{
    padding: 20px;
    border-radius: 5px;
    background-color: var(--lighter-color2);
    display: flex;
    flex-direction: column;
}

.priceContainer
{
    gap: 5px;
}

.priceContainer .price
{
    font-size: 14px;
    font-weight: 100;
    color: var(--black-color1);
    margin-bottom: 15px;
}

.cleaningTypesContainer
{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cleaningTypeItem
{
    display: flex;
    align-items: center;
    gap: 10px;
}

.cleaningTypeItem .cleaningType
{
    font-size: 17px;
    font-weight: 700;
    color: var(--lighter-color);
}

.servicesSearchFiltersItem .title
{
    font-size: 15px;
    font-weight: 600;
}

.ratingDesc
{
    font-size: 12px;
    font-weight: 500;
    color: var(--lighter-color);
}

.ratingContainer
{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 10px;
}

.ratingContainerItem
{
    display: flex;
    align-items: center;
    gap: 20px;
}

.cleaningTypeItem input,
.ratingContainerItem input
{
    height: 20px;
    width: 20px;
    cursor: pointer;
    accent-color: var(--theme-color);
    outline: none;
    box-shadow: none;
    border: 2px solid var(--theme-color);
}

.ratingStars
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.ratingStars i
{
    font-size: 20px;
    color: var(--theme-color1);
}

.ratingStars i.bx-star
{
    color: var(--lighter-color);
}

.ratingCount
{
    color: var(--lighter-color);
    font-size: 14px;
    font-weight: 100;
}

.minValuesContainer
{
    height: 6px;
    width: 100%;
    background-color: var(--theme-color);
}

.maxValuesContainer
{
    height: 20px;
    width: 20px;
    background-color: var(--white-color);
    border-radius: 50%;
    border: 0.025em solid var(--theme-color);
}

.maxValuesContainer:focus
{
    background-color: var(--white-color);
    border-color: var(--theme-color);
    outline: none;
}

.servicesSearchBodyContainer
{
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.servicesSearchBodyHeader
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid var(--light-color2);
    padding: 10px;
}

.servicesSearchBodyHeaderItem
{
    padding: 8px 10px;
    background-color: var(--theme-color3);
    display: flex;
    align-items: center;
    gap: 7px;
}

.servicesSearchBodyHeaderItem i
{
    font-size: 26px;
}

.servicesSearchBodyHeaderItem input,
.servicesSearchBodyHeaderItem select
{
    font-size: 15px;
    font-weight: 600;
    color: var(--lighter-color);
    border: none;
    background-color: transparent;
}

.servicesSearchBodyHeaderItem input:focus,
.servicesSearchBodyHeaderItem select:focus
{
    outline: none;
}

.servicesSearchBodyHeader .servicesSearchBtn
{
    padding: 8px 15px;
    background-color: var(--theme-color);
    font-size: 16px;
    font-weight: 700;
    color: var(--white-color);
    cursor: pointer;
}

.servicesBodyHeaderItem.searchBtns
{
    display: flex;
    align-items: center;
    gap: 15px;
}

.servicesBodyHeaderItem .resetSearch
{
    font-size: 16px;
    color: var(--lighter-color);
    font-weight: 200;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.servicesBodyHeaderItem .resetSearch:hover
{
    text-decoration: underline;
}

.servicesSearchBodyResults
{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.servicesSearchBodyResults .loadingResults,
.servicesSearchBodyResults .emptyResults
{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--theme-color3);
}

.servicesSearchBodyResults .loadingResults
{
    color: var(--theme-color);
}

.servicesSearchBodyResults .emptyResults
{
    color: var(--theme-color2);
}