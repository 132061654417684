.pagination 
{
    margin: 0;
    padding: 10px;
    margin-top: 30px;
    align-items: center;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
    position: relative;
    width: 100%;
    gap: 15px;
}

.pagination li
{
    height: 35px;
    width: 35px;
    background-color: var(--theme-color3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
    color: var(--theme-color);
    cursor: pointer;
}

.pagination .active 
{
    background-color: var(--theme-color);
    color: var(--white-color);
}


  