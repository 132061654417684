.featuredCleaningOperator
{
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(var(--white-color),  var(--white-color), var(--theme-color3));
    
}

.featuredCleaningOperatorContainer
{
    width: 100%;
    padding-bottom: 40px;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.featuredCleaningOperatorHeader .operatorTitle
{
    font-size: 20px;
    font-weight: 800;
}

.featuredCleaningOperatorHeader .operatorTitle .s1
{
    color: var(--theme-color);
}

.featuredCleaningOperatorHeader .operatorTitle sup
{
    color: var(--theme-color2);
}

.featuredCleaningOperatorBody
{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.featuredCleaningOperatorBodyItems
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
}

.featuredCleaningOperatorBody .navItems
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.featuredCleaningOperatorBody .navItems span
{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--theme-color);
    color: var(--white-color);
    font-size: 25px;
    cursor: pointer;
}