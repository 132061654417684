.serviceCard
{
    border: 0.0125em solid var(--lighter-color);
    border-radius: 10px;
    padding: 10px;
}

.serviceCardContainer
{
    display: flex;
    gap: 20px;
}

.serviceCardContainer .logo
{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.imgContainer
{
    height: 100px;
    width: 100px;
}

.imgContainer img
{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.details
{
    flex: 2;
    display: flex;
    flex-direction: column;
}

.serviceName
{
    font-size: 17px;
    font-weight: 700;
    color: var(--black-color);
    cursor: pointer;
}

.serviceName:hover
{
    text-decoration: underline;
}

.ratingDetails
{
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.ratingDetails .rating i
{
    color: var(--theme-color1);
}

.ratingDetails .reviews
{
    font-size: 14px;
    font-weight: 700;
    color: var(--theme-color);
}

.details .desc
{
    margin-bottom: 10px;
    font-size: 13px;
    color: var(--lighter-color);
    font-weight: 500;
}

.serviceContacts
{
    display: flex;
    flex-direction: column;
}

.serviceContacts .serviceItem
{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    color: var(--lighter-color);
}

.serviceItem .serviceItemType
{
    font-weight: 600;
}

.serviceItem .serviceItemValue
{
    margin-left: auto;
    text-align: left;
}

.serviceItem .serviceItemValue.city
{
    text-transform: capitalize;
}

.actions
{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.callNow
{
    margin-top: auto;
    padding: 8px 15px;
    background-color: var(--theme-color);
    color: var(--white-color);
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.callNow a
{
    color: var(--white-color);
    text-decoration: none;
}

