.featuredCity
{
    width: 100%;
    background-image: linear-gradient(var(--lighter-color2), var(--theme-color3));
    display: flex;
    justify-content: center;
}

.featuredCityContainer
{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    min-width: calc(100vw - 120px);
    max-width: calc(100vw - 120px);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.featuredCityHeader
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.featureCityHeaderTitle .title
{
    font-size: 18px;
    font-weight: 900;
}

.featuredCityHeader .navItems
{
    display: flex;
    align-items: center;
    gap: 20px;
}

.featuredCityHeader .navItems span
{
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    color: var(--white-color);
    border-radius: 50%;
}

.featuredCityHeader .navItems span i
{
    font-size: 26px;
    cursor: pointer;
}

.featuredCityBody
{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}